import React from 'react';
import Typography from '@mui/material/Typography';
import NearMeIcon from '@mui/icons-material/NearMe';
import PhoneIcon from '@mui/icons-material/Phone';
import MailIcon from '@mui/icons-material/Mail';
import { Grid, useMediaQuery, useTheme } from '@mui/material';

const Datos = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const styles = {
    container: {
      backgroundColor: '#5796D0',
      color: 'white',
      padding: '20px', // Mayor padding para pantallas grandes
      textAlign: 'center', // Centrado en pantallas pequeñas
    },
    section: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: '10px',
      flexDirection: 'row', // Mantener alineado horizontalmente en ambas versiones
    },
    icon: {
      marginRight: '10px', // Acercar el icono al texto de email
    },
    typography: {
      fontFamily: 'Bree Serif, sans-serif',
      fontWeight: 400,
    },
  };

  return (
    <div style={styles.container}>
      <Grid container spacing={isSmallScreen ? 2 : 3} justifyContent="center">
        {/* Sección de ubicación */}
        <Grid item xs={12} sm={4}>
          <div style={styles.section}>
            <NearMeIcon style={styles.icon} />
            <Typography variant="body2" sx={styles.typography}>
              Central Gonzalo Pérez Llona 1255
            </Typography>
          </div>
        </Grid>

        {/* Sección de correo electrónico */}
        <Grid item xs={12} sm={4}>
          <div style={styles.section}>
            <MailIcon style={styles.icon} />
            <Typography variant="body2" sx={styles.typography}>
              milockers@gmail.com / contacto@milockers.cl
            </Typography>
          </div>
        </Grid>

        {/* Sección de contacto telefónico */}
        <Grid item xs={12} sm={4}>
          <div style={styles.section}>
            <PhoneIcon style={styles.icon} />
            <Typography variant="body2" sx={styles.typography}>
              +56 9 9819 0968
            </Typography>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Datos;
