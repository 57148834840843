import React from 'react';
import {
  Box,
  // TextField,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  // Checkbox,
  // FormControlLabel,
  // Typography,
  Grid,
} from '@mui/material';

const SearchBar = ({
  searchText,
  setSearchText,
  sortOption,
  setSortOption,
  selectedBrands,
  handleBrandChange,
  categories,
  selectedCategory,
  handleCategoryChange,
}) => {
  // const brands = ["Prolockers", "Milockers", "Prosillas", "Starimport", "Starpet"];

  return (
    <Box sx={{ margin: '2rem', padding: '1rem', border: '1px solid #ddd', borderRadius: '8px' }}>
      <Grid container spacing={3}>
      {/* <Grid item xs={12} sm={6}>
        <TextField
        id="buscar-productos"
        label="Buscar Productos"
        placeholder="Buscar..."
        variant="outlined"
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        fullWidth
        required
        InputProps={{
        sx: {
        fontFamily: 'Bree Serif, sans-serif',
        fontWeight: 400,
        textAlign: 'justify',
        textJustify: 'inter-word',
          }
          }}
          InputLabelProps={{
          sx: {
          fontFamily: 'Bree Serif, sans-serif',
          fontWeight: 400,
          textAlign: 'justify',
          textJustify: 'inter-word',
          }
          }}
        />
      </Grid> */}

        <Grid item xs={12} sm={6}>
        <FormControl variant="outlined" fullWidth>
          <InputLabel 
          id="ordenar-label" 
          sx={{ 
          fontFamily: 'Bree Serif, sans-serif', 
          fontWeight: 400, 
          textAlign: 'justify', 
          textJustify: 'inter-word' 
          }}
          >
          Ordenar por
          </InputLabel>
          <Select
          labelId="ordenar-label"
          label="Ordenar por"
          value={sortOption || ""}
          onChange={(e) => setSortOption(e.target.value)}
          required
          sx={{ 
          fontFamily: 'Bree Serif, sans-serif', 
          fontWeight: 400, 
          textAlign: 'justify', 
          textJustify: 'inter-word', 
          marginBottom: '1rem' // Si necesitas mantener el margen
          }}
          >
          <MenuItem value="default" sx={{ 
          fontFamily: 'Bree Serif, sans-serif', 
          fontWeight: 400, 
          textAlign: 'justify', 
          textJustify: 'inter-word' 
          }}>
          Seleccione
          </MenuItem>
          <MenuItem value="titleAsc" sx={{ 
          fontFamily: 'Bree Serif, sans-serif', 
          fontWeight: 400, 
          textAlign: 'justify', 
          textJustify: 'inter-word' 
          }}>
          Nombre (A-Z)
          </MenuItem>
          <MenuItem value="titleDesc" sx={{ 
          fontFamily: 'Bree Serif, sans-serif', 
          fontWeight: 400, 
          textAlign: 'justify', 
          textJustify: 'inter-word' 
          }}>
          Nombre (Z-A)
          </MenuItem>
          <MenuItem value="priceAsc" sx={{ 
          fontFamily: 'Bree Serif, sans-serif', 
          fontWeight: 400, 
          textAlign: 'justify', 
          textJustify: 'inter-word' 
          }}>
          Precio (menor a mayor)
          </MenuItem>
          <MenuItem value="priceDesc" sx={{ 
          fontFamily: 'Bree Serif, sans-serif', 
          fontWeight: 400, 
          textAlign: 'justify', 
          textJustify: 'inter-word'
          }}>
          Precio (mayor a menor)
          </MenuItem>
          </Select>
        </FormControl>

        </Grid>
        <Grid item xs={12} sm={6}>
        <FormControl variant="outlined" fullWidth>
          <InputLabel 
          id="categoria-label" 
          sx={{ 
          fontFamily: 'Bree Serif, sans-serif', 
          fontWeight: 400, 
          textAlign: 'justify', 
          textJustify: 'inter-word' 
          }}
          >
          Categoría
          </InputLabel>
          <Select
          labelId="categoria-label"
          label="Categoría"
          value={selectedCategory}
          onChange={handleCategoryChange}
          required
          sx={{ 
          fontFamily: 'Bree Serif, sans-serif', 
          fontWeight: 400, 
          textAlign: 'justify', 
          textJustify: 'inter-word', 
          marginBottom: '1rem' // Si necesitas mantener el margen
          }}
          >
          <MenuItem 
          value="" 
          sx={{ 
          fontFamily: 'Bree Serif, sans-serif', 
          fontWeight: 400, 
          textAlign: 'justify', 
          textJustify: 'inter-word' 
          }}
          >
          Seleccione
          </MenuItem>
          {categories.map((category) => (
          <MenuItem 
          key={category} 
          value={category}
          sx={{ 
          fontFamily: 'Bree Serif, sans-serif', 
          fontWeight: 400, 
          textAlign: 'justify', 
          textJustify: 'inter-word' 
          }}
          >
          {category}
          </MenuItem>
          ))}
          </Select>
        </FormControl>

        </Grid>
        {/* <Grid item xs={12} sx={{ textAlign: 'center' }}>
          <Typography
            variant="h6"
            component="h5"
            sx={{
              fontWeight: 400, // Ajusta el peso de la fuente
              marginBottom: '1rem',
              fontFamily: 'Bree Serif, sans-serif', // Aplica la familia de fuente
            }}
          >
            Filtrar por Marca
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
            {brands.map((brand) => (
              <FormControlLabel
                key={brand}
                control={
                  <Checkbox
                    checked={selectedBrands.includes(brand)}
                    onChange={handleBrandChange}
                    value={brand}
                  />
                }
                label={
                  <Typography
                    sx={{
                      fontFamily: 'Bree Serif, sans-serif', // Aplica la familia de fuente
                      fontWeight: 400, // Ajusta el peso de la fuente
                    }}
                  >
                    {brand}
                  </Typography>
                }
                sx={{ marginRight: '1rem', marginBottom: '0.5rem' }}
              />
            ))}
          </Box>
        </Grid> */}
      </Grid>
    </Box>
  );
};

export default SearchBar;
