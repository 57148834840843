import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Milockers from '../Image/logo.png';
import carritoImg from '../Image/CARRITO.svg';
import { CartContext } from '../Context/CardContext';
import Carrito from './Compra/Carito';
import {
  Box,
  TextField,
  InputAdornment,
  IconButton,
  Badge,
  Drawer,
  Divider,
  Button,
  Grid,
  Typography,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';

const NavBar = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 10px 20px;
  background-color: #f8f9fa;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    justify-content: space-between;
    flex-direction: row;
  }
`;

const LogoButton = styled(Link)`
  height: 80px;
  background-image: url(${Milockers});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: inherit;

  @media (max-width: 768px) {
    display: none;
  }
`;

const SearchContainer = styled(Box)`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;

  @media (max-width: 768px) {
    justify-content: flex-start;
    flex: 0.7;
    margin: 0;
  }
`;

const StyledSearchForm = styled(Box)`
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border-radius: 30px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 600px;
  padding: 2px 10px;

  @media (max-width: 768px) {
    max-width: 100%;
    padding: 5px;
  }
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    justify-content: flex-end;
    flex: 0.3;
  }
`;

const Buscador = ({ searchText, setSearchText }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { cantidadEnCarrito } = useContext(CartContext);
  const [isOpen, setIsOpen] = useState(false);

  const handleSearch = (value) => {
    if (value.trim() !== '') {
      navigate(`/productos?search=${value}`);
    }
  };

  const toggleDrawer = (open) => () => {
    setIsOpen(open);
  };

  const CarritoComponent = (
    <Box sx={{ width: 250 }} role="presentation">
      <Grid
        container
        style={{
          textAlign: 'center',
          justifyContent: 'space-between',
          padding: '1.3rem',
        }}
      >
        <Typography
          variant="h3"
          sx={{
            fontSize: '1.5rem',
            fontFamily: 'Bree Serif, sans-serif',
            fontWeight: 'bold',
            marginBottom: '1rem',
          }}
        >
          Tu carrito
        </Typography>
        <Button onClick={toggleDrawer(false)} sx={{ color: 'black' }}>
          <CloseIcon />
        </Button>
      </Grid>
      <Divider />
      <Carrito toggleDrawer={toggleDrawer} />
    </Box>
  );

  return (
    <NavBar>
        <LogoButton 
          onClick={() => window.location.href = '/'} 
        />

      {/* Mostrar buscador o texto basado en la ruta */}
      {location.pathname === '/productos' ? (
        <SearchContainer item xs={12} sm={6}>
      <StyledSearchForm
        onSubmit={(e) => {
          e.preventDefault();
          const searchValue = e.target[0].value;
          handleSearch(searchValue);
        }}
      >
        <TextField
          id="buscar-productos"
          placeholder="Buscar productos..."
          variant="standard"
          required
          fullWidth
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          InputProps={{
            disableUnderline: true,
            style: {
              fontFamily: 'Poppins, sans-serif',
              fontWeight: 400,
              fontSize: '16px',
              padding: '10px',
            },
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={(e) => {
                    e.preventDefault();
                    const form = e.target.closest('form');
                    const searchValue = form ? form[0].value : '';
                    handleSearch(searchValue);
                  }}
                  sx={{
                    backgroundColor: '#5796D0',
                    borderRadius: '50%',
                    padding: '10px',
                    color: '#ffffff',
                    '&:hover': {
                      backgroundColor: '#457BA0',
                    },
                  }}
                >
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </StyledSearchForm>
    </SearchContainer>

      ) : (
        <SearchContainer>
          <StyledSearchForm
            component="form"
            onSubmit={(e) => {
              e.preventDefault();
              const searchValue = e.target[0].value;
              handleSearch(searchValue);
            }}
          >
            <TextField
              id="buscar-productos"
              placeholder="Buscar productos..."
              variant="standard"
              required
              fullWidth
              InputProps={{
                disableUnderline: true,
                style: {
                  fontFamily: 'Poppins, sans-serif',
                  fontWeight: 400,
                  fontSize: '16px',
                  padding: '10px',
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={(e) => {
                        e.preventDefault();
                        const form = e.target.closest('form');
                        const searchValue = form ? form[0].value : '';
                        handleSearch(searchValue);
                      }}
                      sx={{
                        backgroundColor: '#5796D0',
                        borderRadius: '50%',
                        padding: '10px',
                        color: '#ffffff',
                        '&:hover': {
                          backgroundColor: '#457BA0',
                        },
                      }}
                    >
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </StyledSearchForm>
        </SearchContainer>
      )}

      <IconContainer>
        <IconButton
          onClick={toggleDrawer(true)}
          style={{ padding: 8 }}
          aria-label="Carrito"
        >
          <Badge
            badgeContent={cantidadEnCarrito()}
            color="secondary"
            overlap="circular"
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            sx={{
              '& .MuiBadge-badge': {
                backgroundColor: '#5796D0',
                color: '#ffffff',
              },
            }}
          >
            <img
              src={carritoImg}
              alt="Carrito"
              style={{ width: '30px', height: '30px', filter: 'invert(1)' }}
            />
          </Badge>
        </IconButton>
      </IconContainer>

      <Drawer anchor="right" open={isOpen} onClose={toggleDrawer(false)}>
        {CarritoComponent}
      </Drawer>
    </NavBar>
  );
};

export default Buscador;
