import React, { useState } from 'react'; 
import styled from 'styled-components'; 
import { Link, useLocation } from 'react-router-dom';
import Button from '@mui/material/Button'; 
import Drawer from '@mui/material/Drawer'; 
import Box from '@mui/material/Box'; 
import Divider from '@mui/material/Divider';
import List from '@mui/material/List'; 
import ListItem from '@mui/material/ListItem'; 
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Milockers from '../Image/logo.png'; 
// import usuarioImg from '../Image/usuario.svg';

// Estilo para el contenedor de navegación
const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background: #FED898;
  color: white;
`;

// Estilo para el botón del logo
const LogoButton = styled(Link)`
  height: 110px;
  background-image: url(${Milockers});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: inherit;
`;

// Estilo para el logo en modo responsivo (visible solo en pantallas pequeñas)
const ResponsiveLogoButton = styled(LogoButton)`
  display: none; // Oculto por defecto

  @media (max-width: 768px) {
    display: flex;
    height: 80px; 
    width: 120px; 
  }
`;

// Estilo para el botón de cancelar (cerrar menú)
const CancelButton = styled(Button)`
  && {
    margin: 0 1rem;
    text-transform: none;
    font-size: 20px;
    color: white;
    background-color: #5796D0;
    &:hover {
      background-color: #4679A7;
    }
  }
`;

// Estilo para el menú de navegación (oculto en pantallas pequeñas)
const Menu = styled.div`
  display: flex;
  @media (max-width: 768px) {
    display: none;
  }
`;

// Estilo para los ítems del menú (botones)
const MenuItem = styled(Button)`
  && {
    color: #37424F;
    margin: 0 1rem;
    text-transform: none;
    font-size: 20px;
  }
`;

// Estilo para el icono de menú (hamburguesa) (visible en pantallas pequeñas)
const Hamburger = styled.div`
  display: none;
  cursor: pointer;
  margin-right: auto;

  @media (max-width: 768px) {
    display: block;
  }
`;

const Navbar = ({ fixed }) => {
  const location = useLocation();
  const [menuOpen, setMenuOpen] = useState(false);

  // Función para abrir o cerrar el cajón del menú
  const toggleMenuDrawer = (newOpen) => () => {
    setMenuOpen(newOpen);
  };

  // Verifica si la página actual es la de confirmación
  const isConfirmationPage = location.pathname === '/confirmacion';

  // Lista de navegación para dispositivos móviles
  const DrawerList = (
    <Box sx={{ width: 250 }} role="presentation" onClick={toggleMenuDrawer(false)}>
      <List>
        {[
          { text: 'Inicio', href: '/' },
          { text: 'Productos', href: '/productos' },
          { text: 'Fabricacion a pedido', href: '/pedidos' },
          { text: 'Contacto', href: '/contacto' },
        ].map(({ text, href }) => (
          <ListItem key={href} disablePadding>
            <ListItemButton onClick={() => (window.location.href = href)}>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
    </Box>
  );

  return (
    <Nav fixed={fixed}>
      {/* Renderiza un diseño diferente si estamos en la página de confirmación */}
      {isConfirmationPage ? (
        <>
          <LogoButton 
            onClick={() => window.location.href = '/'} 
          />
            <CancelButton
              variant="outlined"
              onClick={() => window.location.href = '/'}
              sx={{
                fontFamily: 'Bree Serif, sans-serif',
                fontWeight: 400,                  
                textAlign: 'center',              
                textJustify: 'inter-word',         
              }}
            >
              Seguir comprando
            </CancelButton>
        </>
      ) : (
        <>
          <Hamburger onClick={toggleMenuDrawer(true)}>
            <MenuIcon style={{ color: 'white' }} />
          </Hamburger>

          <ResponsiveLogoButton onClick={() => window.location.href = '/'} />

          <Menu>
            {/* Menú de navegación visible en pantallas grandes */}
            <MenuItem 
              component={Link} 
              onClick={() => window.location.href = '/'} 
              sx={{ 
                fontFamily: 'Bree Serif, sans-serif', 
                fontWeight: 400, 
                color: 'black', 
                padding: '10px 20px', 
                '&:hover': {
                  backgroundColor: '#5796D0', 
                  color: 'white'
                },
                textDecoration: 'none'
              }}
            >
              Inicio
            </MenuItem>
            
            <MenuItem 
              component={Link} 
              to="/productos" 
              sx={{ 
                fontFamily: 'Bree Serif, sans-serif', 
                fontWeight: 400, 
                color: 'black', 
                padding: '10px 20px', 
                '&:hover': {
                  backgroundColor: '#5796D0', 
                  color: 'white'
                },
                textDecoration: 'none'
              }}
            >
              Productos
            </MenuItem>

            <MenuItem 
              component={Link} 
              to="/pedidos" 
              sx={{ 
                fontFamily: 'Bree Serif, sans-serif',
                fontWeight: 400,
                color: 'black',
                padding: '10px 20px',
                '&:hover': {
                  backgroundColor: '#5796D0',
                  color: 'white'
                },
                textDecoration: 'none'
              }}
            >
              Fabricacion a pedido
            </MenuItem>

              <MenuItem
                component={Link}
                to="/contacto"
                sx={{
                  fontFamily: 'Bree Serif, sans-serif',
                  fontWeight: 400,
                  color: 'black',
                  padding: '10px 20px',
                  '&:hover': {
                    backgroundColor: '#5796D0',
                    color: 'white'
                  },
                  textDecoration: 'none'
                }}
              >
                Contacto
              </MenuItem>

            {/* <MenuItem 
              component={Link} 
              to="/blog" 
              sx={{ 
                fontFamily: 'Bree Serif, sans-serif', 
                fontWeight: 400, 
                color: 'black', 
                padding: '10px 20px',
                '&:hover': {
                  backgroundColor: '#ff4d4d', 
                  color: 'white'
                },
                textDecoration: 'none'
              }}
            >
              Blog
            </MenuItem> */}

          </Menu>

          {/* Cajón del menú */}
          <Drawer anchor="left" open={menuOpen} onClose={toggleMenuDrawer(false)}>
            {DrawerList}
          </Drawer>
        </>
      )}
    </Nav>
  );
};

export default Navbar;
